import React, { Suspense, useContext } from 'react';
import { useRoutes } from 'react-router-dom';
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";
import {AuthFirebaseContext} from "../Context/AuthFirebaseContext";
// components
import Root from './Root';
import PrivateRoute from './PrivateRoute';
import CrmErrorPage from "../pages/Errors/CrmErrorPage";
//import Welcome from '../pages/Welcome';
//import CrmLayout from "../components/layout/crm"



// lazy load all the views


const CrmLayout = React.lazy(() => import('../components/layout/crm'));
const Welcome = React.lazy(() => import('../pages/Welcome'));
const Home = React.lazy(() => import('../pages/Home'));
const Leads = React.lazy(() => import('../pages/Leads'));
const Contacts = React.lazy(() => import('../pages/Contacts'));
const Accounts = React.lazy(() => import('../pages/Accounts'));
const Tasks = React.lazy(() => import('../pages/Tasks'));
const Meetings = React.lazy(() => import('../pages/Meetings'));
const Deals = React.lazy(() => import('../pages/Deals'));
const Calls = React.lazy(() => import('../pages/Calls'));
const Reports = React.lazy(() => import('../pages/Reports'));


// auth & Validation
const CrmAccessValidtor = React.lazy(() => import('pages/CrmAccess/CrmAccessValidtor'));
const InvalidCrmAccess = React.lazy(() => import('pages/CrmAccess/InvalidCrmAccess'));


// home
const Dashboard = React.lazy(() => import('../pages/Dashboard'));


// landings
const Startup = React.lazy(() => import('../pages/landings/Startup'));


// pages
const Settings = React.lazy(() => import('../pages/other/account/Settings'));



const loading = () => <div className=""></div>;

/*
type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
    error: string ;
};
*/

const LoadComponent = ({ component: Component, error : error }) => (
    <ErrorBoundary fallback={<div>{error}</div>}>
        <Suspense fallback={loading()}>
            <Component />
        </Suspense>
    </ErrorBoundary>
);

function fallbackAuthRender(error) {
    // Call resetErrorBoundary() to reset the error boundary and retry the render.
  
    return (
      <div role="alert">
        <p>Something went wrong in login activities.. i will style later:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  }

const AllRoutes = () => {

    // const { user } = useContext(AuthFirebaseContext);
    
    return useRoutes([
        {
            // root route
            path: '/',
            element: <LoadComponent component={Startup} error="Startup Error"/>,
        },
        {
            // public routes
            path: '/',
            children: [
                {
                    path: 'gettingstarted',
                    children: [
                        { path: 'settingup', element: <LoadComponent component={CrmAccessValidtor} error="setting up issue"/> },
                        { path: 'requestaccessauthourisation', element: <LoadComponent component={InvalidCrmAccess} error="invalid access error"/> },
                    ],
                },
                
            ],
        },
        {
            // protected routes
            path: 'crm',
            element: <PrivateRoute  />, 
            errorElement : <CrmErrorPage />,
            children: [
                {   
                    path:"lantern",
                    element: <LoadComponent component={CrmLayout} error="layout Error"/>,
                    children: [
                        { index: true, element: <Welcome /> },
                        {
                            path: ':userid',
                            children: [
                                { path: 'tab/lantern/init', element: <LoadComponent component={Dashboard} error="lgin isssue error"/> },
                                { path: 'tab/home', element: <LoadComponent component={Home} error="crm home isssue error"/> },
                                { path: 'tab/leads', element: <LoadComponent component={Leads} error="leads isssue error"/> },
                                { path: 'tab/contacts', element: <LoadComponent component={Contacts} error="contacts isssue error"/> },
                                { path: 'tab/accounts', element: <LoadComponent component={Accounts} error="accounts isssue error"/> },
                                { path: 'tab/deals', element: <LoadComponent component={Deals} error="deals isssue error"/> },
                                { path: 'tab/tasks', element: <LoadComponent component={Tasks} error="tasks isssue error"/> },
                                { path: 'tab/meetings', element: <LoadComponent component={Meetings} error="meetings isssue error"/> },
                                { path: 'tab/reports', element: <LoadComponent component={Reports} error="report isssue error"/> },
                                { path: 'tab/calls', element: <LoadComponent component={Calls} error="calss isssue error"/> },
                                { path: 'settings', element: <LoadComponent component={Settings} error="lgin isssue error"/> },

                            ],
                        },
                    ],
                },
               
            ],
        },
        {
            // protected routes
            path: '/',
            element: <PrivateRoute  />,
            children: [
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'account',
                            children: [
                               
                                { path: 'settings', element: <LoadComponent component={Settings} error="lgin isssue error"/> },
                            ],
                        },
                    ],
                },
            ],
        },
    ]);
};

export default AllRoutes;
