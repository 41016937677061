import { createContext, useContext, useEffect, useState } from "react";
import { firebase,initializeApp } from 'firebase/app';
import { auth,app,fireStore, } from "../firebase/Firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, setDoc , getDoc, getFirestore , collection } from "firebase/firestore";
import {
    onAuthStateChanged,
    signInWithCustomToken,    
    User
} from "firebase/auth";
import Spinner from "../components/ToolKits/Spinner";

// Create a new context
const AuthFirebaseContext = createContext();

const AuthFirebaseContextProvider = ({ children }) => {
  // Define the state or data you want to share
  const [user, setUser] = useState(null);
   const [authLoaded, setAuthLoaded] = useState(false);

  const db = getFirestore(app);

  const functions = getFunctions(app);


  function signInCustomToken(token) {
    return signInWithCustomToken(auth, token)
  }


  useEffect(() => {
    const subscribe = onAuthStateChanged(auth, (currentuser) => {
      console.log("Auth", currentuser);
      setUser(currentuser);

      setAuthLoaded(true);
    });

    return () => {
      subscribe();
    };
  }, []);

  const loadingComponent = <Spinner />;
  
  // Provide the data and functions through the context
  const contextValue = {
    user,
    signInCustomToken,
    functions,
    httpsCallable,
    loadingComponent,
  };

  return <AuthFirebaseContext.Provider value={contextValue}> {authLoaded ? children : loadingComponent}</AuthFirebaseContext.Provider>;
};
export { AuthFirebaseContext, AuthFirebaseContextProvider };