import { useEffect } from 'react';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import dark from './themes/dark';
import light from './themes/light';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ErrorBoundary } from "react-error-boundary";
import { AuthFirebaseContextProvider, AuthFirebaseContext } from './Context/AuthFirebaseContext';
import { HelmetProvider } from 'react-helmet-async';

import AOS from 'aos';

// routes
import Routes from './routes/Routes';

// helpers
import { configureFakeBackend } from './helpers';

// Themes
//  For Default import Theme.scss
import './assets/scss/theme.scss';

const App = () => {
    const uiState = useSelector((state) => state.ui);
    const dispatch = useDispatch();
    
    const themeSetter = (options) => {
        let themeselector;
      
        if (options === 'light') {
          themeselector = light;
        } else if (options === 'dark') {
          themeselector = dark;
        }
        return themeselector;
      };
      
   
    const uiThemeMode = themeSetter(uiState.theme);
    const themetest = createTheme(uiThemeMode);

    useEffect(() => {
        AOS.init();
    }, []);

    configureFakeBackend();
    return (
        <ThemeProvider theme={themetest}>
          <AuthFirebaseContextProvider> 
            <HelmetProvider> 
              <Routes />
            </HelmetProvider>
          </AuthFirebaseContextProvider>
        </ThemeProvider>  
    );
};

export default App;
