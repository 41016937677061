
const initialState = {
  base: {
    todos: [],
    test: false,
    theme: 'dark',
  },
  ui: {
    theme: localStorage.getItem('ixtohvcrsdg') === null ? 'light':localStorage.getItem('ixtohvcrsdg'),
    selectedlink: 0,
  },
  lang: {
    theme: localStorage.getItem('ixuexhterui') === null ? 'en': localStorage.getItem('ixuexhterui'),
    selectedlink: 0,
  },
  connect: {
    status: window.navigator.onLine,
    localstorage: true, // pick this from electron storage db as its mainly for config s no sqlite
    dataSource: 'onlinefirst', // options {onlinefirst, offlinefirst}
  },
};

export default initialState;
