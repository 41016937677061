import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';

const CrmErrorPage = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}
    >
      <ErrorIcon sx={{ fontSize: 120, color: '#ff0000' }} />
      <Typography variant="h4" sx={{ fontWeight: 'bold', margin: 2 }}>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="h6" sx={{ margin: 2 }}>
        We are sorry for the inconvenience. Please try again later.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        sx={{ margin: 2 }}
        onClick={() => window.location.reload()}
      >
        Reload Page
      </Button>
    </Box>
  );
};

export default CrmErrorPage;
