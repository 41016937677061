import initialState from './initialState';

export default function baseReducer(state = initialState.base, action) {
  switch (action.type) {
    case 'ADD_TODO':
      return {
        ...state,
        todos: state.todos.push(action.todo),
      };
    case 'REMOVE_TODO':
      return {
        ...state,
        todos: state.todos.filter((todo) => todo !== action.todo),
      };
    default:
      return state;
  }
}
