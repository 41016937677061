import { combineReducers } from 'redux';
import baseReducer from './baseReducer';
import uiReducer from './uiReducer';
import langReducer from './langReducer';
import connectReducer from './connectReducer';

const rootReducer = combineReducers({
  base: baseReducer,
  ui: uiReducer,
  lang: langReducer,
  connect: connectReducer,
});

export default rootReducer;
