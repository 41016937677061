import initialState from './initialState';

export default function connectReducer(state = initialState.connect, action) {
  switch (action.type) {
    case 'GET_NETWORK_STATUS':
      return {
        ...state,
        status: action.payload,
      };
    case 'GET_LOCAL_DB_ALLOW':
      return {
        ...state,
        local: action.payload,
      };

    default:
      return state;
  }
}
