export const getCookie = (name) => {
  const cookies = document.cookie.split(';');
  for (const cookie of cookies) {
    const parts = cookie.trim().split('=');
    if (parts[0] === name) {
      return parts[1];
    }
  }
  return null;
};

export const setCookie = (name, value, options = {}) => {
  const expires = options.expires ? options.expires.toUTCString() : '';
  const path = options.path ? options.path : '/';
  const domain = options.domain ? options.domain : '';
  const secure = options.secure ? 'secure' : '';
  const sameSite = options.sameSite ? options.sameSite : '';

  document.cookie = `${name}=${value};expires=${expires};path=${path};domain=${domain};${secure};sameSite=${sameSite}`;
};

export const unsetCookie = (name,domain,sameSite) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=${domain};true;sameSite=${sameSite}`;
};