import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

export default function Spinner() {

    // Create a custom styled component with your CSS styles
    const Spinner = styled(CircularProgress)(({ theme }) => ({
      height: 60,
      width: 60,
      position: 'absolute',
      top: '50%',
      left: '50%',
      margin: '-30px 0 0 -30px',
      border: '6px solid rgba(0, 0, 0, 0.2)',
      borderRadius: '100%',
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: -6,
        top: -6,
        height: '100%',
        width: '100%',
        borderTop: '6px solid rgba(0, 0, 0, 0.8)',
        borderLeft: '6px solid transparent',
        borderBottom: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderRadius: '100%',
      },
    }));

  return <Spinner />;
}