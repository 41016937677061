import React from 'react';
import ReactDOM from 'react-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/400-italic.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import "@fontsource/raleway"; // Defaults to weight 400
import "@fontsource/raleway/400.css"; // Specify weight
import "@fontsource/raleway/400-italic.css"; // Specify weight and style
import "@fontsource/raleway/500.css"; 
import "@fontsource/raleway/600.css"; 
import "@fontsource/raleway/700.css"; 
import "@fontsource/raleway/800.css"; 
import "@fontsource/raleway/900.css"; 
import { Provider } from 'react-redux';
import configureStore from './store/store';
import './i18/i18n';

import './i18n';

import App from './App';
import reportWebVitals from './reportWebVitals';

const store = configureStore();

//set theme
if (localStorage.getItem('ixtohvcrsdg') !== null) {
    // The user_name key is set.
    console.log('theme already here');
    console.log(localStorage.getItem('ixtohvcrsdg'));
  } else{
    localStorage.setItem('ixtohvcrsdg','light');
    console.log('theme set');
  }
  
  //set lang
  if (localStorage.getItem('ixuexhterui') !== null) {
    // The user_name key is set.
    console.log('lang already here');
    console.log(localStorage.getItem('ixuexhterui'));
  } else{
    localStorage.setItem('ixuexhterui','light');
    console.log('lang set');
  }

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
