import react, { Suspense,useEffect, useContext , useState} from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import {AuthFirebaseContext} from "../Context/AuthFirebaseContext";
import { setCookie,getCookie } from "../utils/cookie";
import Spinner from '../components/ToolKits/Spinner';


const loading = () => <Spinner />;


const PrivateRoute = (props) => {
  
   
    const { user } = useContext(AuthFirebaseContext);
    const [crmRole, setCrmRole] = useState(false);

    const crmCookie = getCookie('crminitiate'); // get the cookie value
    
    useEffect(() => {
        if (user) {
          // get the user's token and check for the custom claim
          user.getIdTokenResult().then((tokenResult) => {
            if (tokenResult.claims.crmRole) {
              setCrmRole(true);
            } else {
              setCrmRole(false);
            }
          });
        }
      }, [user]);
    /**
     * not logged in so redirect to login page with the return url
     */
      //renable this section back before prouction.. disabled on purpose to allow local dev
    
    if (!user) {
        return <Navigate to={'/'}  replace />;
    }

    // check if route is restricted by role
    if (!crmRole && !crmCookie) {
        // role not authorised so redirect to home page
        return <Navigate to={{ pathname: '/' }} />;
    }
    return (
        <Suspense fallback={loading()}>
            <Outlet/>
        </Suspense>
    );
};

export default PrivateRoute;
